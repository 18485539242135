.registration-form {
	width: 650px !important;
}

.text-trial-signup {
	color:red; 
	font-size:28px;
}

.checkbox,.radio{
    margin-top:0px !important;
    margin-bottom:0px !important
}

.change-pie {
	cursor: pointer;
}

.grunt-det-popover {
	font-size: 13px;
	font-weight: normal;
	margin-top: 10px;
	margin-bottom: 10px;
}

.grunt-name {
	text-transform: capitalize;
	font-weight: bold;
	text-align: center;
	width: 100%
}

.grunt-position {
	margin-top: 0px !important;
}

.grunt-last-active {
	font-size: 11px !important;
}

.content-bargroup {
	margin-bottom: 10px !important;
	text-align: left !important;
}

.bar-category {
	margin: 0 0 1px;
}

.title-label {
	text-transform: uppercase;
    font-size: 13px;
}

.title-value {
	font-size: 13px;
    float: right;
    font-weight: bold;
    text-align: right;
}

.btn-group-grunt {
	border-radius: 0px !important;
}

.pie-slices-count {
	margin-left: 10px;
	font-size: 16pt;
	font-weight: bold;
}

.c3-chart-texts .c3-text {
        display: none;
}

.grunt-legend {
	border-left: 10px solid;
    width: 100px;
    height: 35px;
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 15px;
}

.grunt-legend-text {
	margin-left: 5px;
    margin-top: -4px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.grunt-legend-text .name {
    font-weight: bold;
    white-space: nowrap;
}

.grunt-legend-text .value {
    color: #888;
}

.pie-legend {
	margin-top: 10px;
}

.pie-slives-well-value {
	font-weight: bold;
	font-size: 100%;
}

.well-value {
	font-weight: bold;
	vertical-align: top;
	margin-left: 10px;
	font-size: 16pt;
}

.manage-funds {
	vertical-align: top;
	font-size: 35px;
    display: inline-block;
}

.chart-label {
	font-size: 15px;
}

.manage-funds-icon{
	font-size: 30px;
	margin-top: 10px;
	cursor: pointer;
}

.heading-btn-group .text-primary{
	font-size: 20px;
}

.requiredFields {
	color: red;
	font-weight: bold;
}

.header-pie-name {
	font-size: 26px !important;
}

.pie-name-heading {
	padding-top: 24px !important;
}

.noUi-horizontal {
	width: 210px;
}

#noui-stepping-val {
	margin-left: 10px;
	font-weight: bold;
	font-size: 15px;
}


.bg-projects-tags {
	background-color: #fff8df;
	color: #555555;
	font-weight: bold;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	font-size: 13px;
	padding: 2px;
}

.tokenfield {
	border: none !important;
}

.helper-icon {
	margin-top: 5px;
	margin-left: 5px;
	cursor: pointer;
}

.close .popover {
	left: -168px;
	width: 500px !important;
}

.foot-links {
    position: relative;
    top: 20px;
    margin-bottom: 20px;
    padding: 0;
    list-style-type: none;
    display: inline-block;
    color: #e0e0e0;
}

.margleft {
    margin-left: 15px;
}

.foot-links a {
    color: #696969;
}

.btn-harvest {
    background: #ea6b00 !important;
    border-color: #ea6b00 !important;
    color: white !important;
}

.margin-left {
	margin-left: 10px;
}

.toolbar-label {
    font-size: 15px;
    display: inline-block;
    vertical-align: top;
}

.grunt-name {
    padding-left: 10px;
    border-left: 5px solid;
    font-weight: bold;
}

.tooltip-actions {
	cursor: pointer;

}

.sidebar-xs-indicator .sidebar-fixed .sidebar-content{
    top:0!important
}
@media (min-width:769px){
    .has-detached-left .container-detached{
        float:right;
        margin-left:-260px;
        width:100%
    }
    .has-detached-left .content-detached{
        margin-left:280px
    }
    .has-detached-left .sidebar-detached{
        float:left
    }
    .has-detached-right .container-detached{
        float:left;
        margin-right:-260px;
        width:100%
    }
    .has-detached-right .content-detached{
        margin-right:280px
    }
    .has-detached-right .sidebar-detached{
        float:right
    }
    .has-detached-right .sidebar-detached.affix{
        right:20px
    }
    .sidebar-detached-hidden .container-detached{
        float:none;
        margin:0
    }
    .sidebar-detached-hidden .content-detached{
        margin:0
    }
    .sidebar-detached-hidden .sidebar-detached{
        float:none
    }
}
.sidebar-detached .navigation.nav>.active>.hidden-ul{
    display:block
}
@media (max-width:768px){
    .sidebar-detached .navigation.nav>li>.hidden-ul{
        display:block
    }
}
.sidebar-detached.affix{
    position:static
}
@media (min-width:769px){
    .sidebar-detached{
        display:block;
        position:relative;
        margin-bottom:20px
    }
    .sidebar-detached>.sidebar-default{
        border:1px solid #ddd;
        -webkit-box-shadow:0 1px 1px rgba(0,0,0,.05);
        box-shadow:0 1px 1px rgba(0,0,0,.05)
    }
    .sidebar-detached>.sidebar{
        margin-bottom:0;
        display:block;
        border-radius:3px
    }
    .sidebar-detached.affix{
        position:fixed;
        top:20px;
        bottom:20px;
        -webkit-transition:bottom ease-in-out .15s;
        -o-transition:bottom ease-in-out .15s;
        transition:bottom ease-in-out .15s
    }
    .sidebar-detached.affix>.sidebar{
        max-height:100%;
        overflow-y:auto
    }
    .sidebar-detached.fixed-sidebar-space{
        bottom:80px
    }
    .navbar-bottom .sidebar-detached.fixed-sidebar-space{
        bottom:86px
    }
    .navbar-bottom-lg .sidebar-detached.fixed-sidebar-space{
        bottom:90px
    }
    .navbar-bottom-sm .sidebar-detached.fixed-sidebar-space{
        bottom:84px
    }
    .navbar-bottom-xs .sidebar-detached.fixed-sidebar-space{
        bottom:82px
    }
    .navbar-fixed .sidebar-detached{
        top:86px
    }
    .navbar-fixed-lg .sidebar-detached{
        top:90px
    }
    .navbar-fixed-sm .sidebar-detached{
        top:84px
    }
    .navbar-fixed-xs .sidebar-detached{
        top:82px
    }
}
.sidebar-separate .sidebar-content{
    padding-bottom:0
}
.sidebar-separate .sidebar-content .panel:last-child,.sidebar-separate .sidebar-content .sidebar-category:last-child{
    margin-bottom:0
}
@media (min-width:769px){
    .sidebar-separate{
        background-color:transparent
    }
    .sidebar-separate .sidebar-category{
        background-color:#263238;
        border-radius:3px;
        margin-bottom:20px
    }
    .sidebar-separate.sidebar-default{
        background-color:transparent;
        border:0;
        -webkit-box-shadow:none;
        box-shadow:none
    }
    .sidebar-separate.sidebar-default .sidebar-category{
        background-color:#fff;
        border:1px solid #ddd;
        -webkit-box-shadow:0 1px 1px rgba(0,0,0,.05);
        box-shadow:0 1px 1px rgba(0,0,0,.05)
    }
}
@media (min-width:769px){
    .sidebar-xs .sidebar-main{
        width:56px
    }
    .sidebar-xs .sidebar-main .sidebar-category{
        display:none
    }
    .sidebar-xs .sidebar-main .sidebar-category-visible{
        display:block
    }
    .sidebar-xs .sidebar-main .category-title{
        padding:0
    }
    .sidebar-xs .sidebar-main .category-title>i{
        padding:13px 0;
        float:none;
        display:block;
        top:0
    }
    .sidebar-xs .sidebar-main .category-title>span{
        display:none
    }
    .sidebar-xs .sidebar-main .category-title .icons-list{
        position:static;
        text-align:center;
        margin-top:0;
        padding-top:14px;
        padding-bottom:14px
    }
    .sidebar-xs .sidebar-main .category-title .icons-list>li{
        display:block;
        margin-left:0
    }
    .sidebar-xs .sidebar-main .category-title .icons-list>li+li{
        margin-top:10px
    }
    .sidebar-xs .sidebar-main .category-title.h6 .icons-list{
        padding-top:23.538462px;
        padding-bottom:23.538462px
    }
    .sidebar-xs .sidebar-main .category-title.h5 .icons-list{
        padding-top:25.0769236px;
        padding-bottom:25.0769236px
    }
    .sidebar-xs .sidebar-main .navigation>li>a{
        display:block;
        text-align:center;
        padding-left:0;
        padding-right:0
    }
    .sidebar-xs .sidebar-main .navigation>li>a>span{
        display:none;
        position:absolute;
        top:0;
        right:-260px;
        background-color:#26a69a;
        border:1px solid #26a69a;
        padding:11px 20px;
        width:260px;
        text-align:left;
        color:#fff;
        cursor:pointer;
        border-bottom-right-radius:3px;
        border-top-right-radius:3px
    }
    .sidebar-xs .sidebar-main .navigation>li>a>span .badge,.sidebar-xs .sidebar-main .navigation>li>a>span .label{
        background-color:rgba(0,0,0,.35);
        border-color:transparent
    }
    .sidebar-xs .sidebar-main .navigation>li>a>i{
        margin:2px 0;
        display:block;
        float:none
    }
    .sidebar-xs .sidebar-main .navigation>li.active>.has-ul:after,.sidebar-xs .sidebar-main .navigation>li>.has-ul:after{
        content:none
    }
    .sidebar-xs .sidebar-main .navigation>li .has-ul>span{
        border-radius:0 3px 0 0;
        cursor:default
    }
    .sidebar-xs .sidebar-main .navigation>li.active>ul{
        display:none!important
    }
    .sidebar-xs .sidebar-main .navigation>li.disabled:hover>a>span,.sidebar-xs .sidebar-main .navigation>li.disabled:hover>ul{
        display:none!important
    }
    .sidebar-xs .sidebar-main .navigation>li:hover>ul{
        display:block!important
    }
    .sidebar-xs .sidebar-main .navigation>li:hover>a>span{
        display:block
    }
    .sidebar-xs .sidebar-main .navigation>li:hover:not(.active)>a{
        background-color:rgba(0,0,0,.1);
        color:#fff
    }
    .sidebar-xs .sidebar-main .navigation>li>ul{
        position:absolute;
        right:-260px;
        top:44px;
        width:260px;
        display:none;
        background-color:#263238;
        border-left:1px solid rgba(255,255,255,.1);
        border-radius:0 0 3px 0
    }
    .sidebar-xs .sidebar-main .navigation>li>ul>li>a{
        padding-left:20px
    }
    .sidebar-xs .sidebar-main .navigation>li>ul>li>a:not(.has-ul){
        padding-right:20px
    }
    .sidebar-xs .sidebar-main .navigation>li>ul>li>ul>li>a{
        padding-left:30px
    }
    .sidebar-xs .sidebar-main .navigation>li>ul>li>ul>li>ul>li>a{
        padding-left:60px
    }
    .sidebar-xs .sidebar-main .navigation>.navigation-header{
        padding:0;
        text-align:center
    }
    .sidebar-xs .sidebar-main .navigation>.navigation-header>i{
        display:block;
        top:0;
        padding:13px 0
    }
    .sidebar-xs .sidebar-main .navigation>.navigation-header>span{
        display:none
    }
    .sidebar-xs .sidebar-main .sidebar-user .category-content{
        padding-left:0;
        padding-right:0
    }
    .sidebar-xs .sidebar-main .sidebar-user .media-left,.sidebar-xs .sidebar-main .sidebar-user .media-right{
        padding:0;
        text-align:center;
        display:block
    }
    .sidebar-xs .sidebar-main .sidebar-user .media-left>img,.sidebar-xs .sidebar-main .sidebar-user .media-right>img{
        max-width:100%;
        height:auto!important
    }
    .sidebar-xs .sidebar-main .sidebar-user .media-left>.img-sm,.sidebar-xs .sidebar-main .sidebar-user .media-right>.img-sm{
        margin-top:1px;
        margin-bottom:1px
    }
    .sidebar-xs .sidebar-main .sidebar-user .media-left>.img-xs,.sidebar-xs .sidebar-main .sidebar-user .media-right>.img-xs{
        margin-top:3px;
        margin-bottom:3px
    }
    .sidebar-xs .sidebar-main .sidebar-user .media-body,.sidebar-xs .sidebar-main .sidebar-user .media-right{
        display:none
    }
    .sidebar-xs .sidebar-main .navigation-lg>li>ul{
        top:48px
    }
    .sidebar-xs .sidebar-main .navigation-sm>li>ul{
        top:40px
    }
    .sidebar-xs .sidebar-main .navigation-xs>li>ul{
        top:36px
    }
    .sidebar-xs .sidebar-main.sidebar-default .navigation>li>a>span{
        background-color:#f5f5f5;
        border-color:#ddd;
        color:#333
    }
    .sidebar-xs .sidebar-main.sidebar-default .navigation>li>a>span .badge,.sidebar-xs .sidebar-main.sidebar-default .navigation>li>a>span .label{
        color:#333
    }
    .sidebar-xs .sidebar-main.sidebar-default .navigation>li>ul{
        background-color:#fff;
        border:1px solid #ddd;
        border-top:0
    }
    .sidebar-xs .sidebar-main.sidebar-default .navigation>li:hover:not(.active)>a{
        background-color:#f5f5f5;
        color:#333
    }
}
.sidebar .row{
    margin-left:-5px;
    margin-right:-5px
}
.sidebar .row [class*=col-]{
    padding-left:5px;
    padding-right:5px
}
@media (max-width:768px){
    .sidebar .sp-container{
        display:block;
        width:220px;
        margin-left:auto;
        margin-right:auto
    }
}
.sidebar .sp-flat .sp-picker-container{
    display:block;
    width:218px
}
.sidebar .panel-group .panel{
    border-radius:0;
    border-width:0 0 1px 0
}
.sidebar .panel-group .panel:first-child{
    border-top-width:1px
}
.sidebar .panel-group .panel+.panel{
    margin-top:0
}
.sidebar .media-list-bordered>li{
    border-top:0;
    border-bottom:1px solid #eee
}
.sidebar:not(.sidebar-default) .media .media-annotation,.sidebar:not(.sidebar-default) .media .text-muted{
    color:rgba(255,255,255,.8)
}
.sidebar:not(.sidebar-default) .media .media-body>a,.sidebar:not(.sidebar-default) .media .media-left>a,.sidebar:not(.sidebar-default) .media .media-right>a{
    color:#fff
}
.sidebar:not(.sidebar-default) .media .media-link:focus,.sidebar:not(.sidebar-default) .media .media-link:hover{
    background-color:rgba(0,0,0,.1)
}
.sidebar:not(.sidebar-default) .media .media-badge{
    border-color:#263238
}
.sidebar:not(.sidebar-default) .media-list-bordered>li{
    border-color:rgba(255,255,255,.1)
}
.sidebar .thumbnail{
    margin-bottom:10px
}
.sidebar .thumbnail:last-child{
    margin-bottom:0
}
.sidebar .thumbnail .zoom-image i{
    font-size:16px;
    margin-top:-8px;
    margin-left:-8px
}
.sidebar .sidebar-category .checkbox,.sidebar .sidebar-category .radio{
    margin-top:0
}
.sidebar .sidebar-category .checkbox:last-child,.sidebar .sidebar-category .radio:last-child{
    margin-bottom:0
}
.sidebar .form-group:last-child{
    margin-bottom:0
}
.sidebar .nav-tabs{
    border-width:0 0 1px 0
}
@media (min-width:769px){
    .sidebar .nav-tabs{
        border-width:1px
    }
    .sidebar .nav-tabs>li>a{
        border-bottom-color:rgba(255,255,255,.1);
        background-color:rgba(0,0,0,.2);
        color:rgba(255,255,255,.6);
        border-top:0;
        border-radius:0
    }
    .sidebar .nav-tabs>li>a:focus,.sidebar .nav-tabs>li>a:hover{
        color:#fff;
        border-bottom-color:rgba(255,255,255,.1)
    }
    .sidebar .nav-tabs>.active>a,.sidebar .nav-tabs>.active>a:focus,.sidebar .nav-tabs>.active>a:hover{
        border-top:0;
        border-bottom:0;
        background-color:transparent;
        border-color:rgba(255,255,255,.1);
        color:#fff
    }
    .sidebar .nav-tabs>.active:first-child>a{
        border-left-color:transparent!important
    }
    .sidebar .nav-tabs>.active:last-child>a{
        border-right-color:transparent!important
    }
    .sidebar .nav-tabs>.open>a{
        color:#fff
    }
    .sidebar .nav-tabs>.open:not(.active)>a{
        background-color:rgba(0,0,0,.2)
    }
}
@media (min-width:769px){
    .sidebar-default .nav-tabs>li>a{
        background-color:#fafafa;
        border-bottom-color:#ddd;
        color:#999
    }
    .sidebar-default .nav-tabs>li>a:focus,.sidebar-default .nav-tabs>li>a:hover{
        color:#333;
        border-bottom-color:#ddd
    }
    .sidebar-default .nav-tabs>.active>a,.sidebar-default .nav-tabs>.active>a:focus,.sidebar-default .nav-tabs>.active>a:hover{
        border-color:#ddd;
        color:#333
    }
    .sidebar-default .nav-tabs>.open>a{
        border-bottom-color:#ddd;
        color:#333
    }
    .sidebar-default .nav-tabs>.open:not(.active)>a{
        background-color:#fafafa
    }
}
@media (min-width:769px){
    .sidebar-default .nav-justified>li:first-child .dropdown-menu:not(.dropdown-menu-right){
        left:-1px
    }
    .sidebar-default .nav-justified>li:last-child .dropdown-menu-right{
        right:-1px
    }
}
@media (min-width:769px){
    .sidebar-fixed .sidebar-content{
        position:fixed;
        width:260px;
        max-height:100%;
        overflow:auto;
        top:48px;
        bottom:48px;
        margin-bottom:-48px
    }
    .sidebar-xs .sidebar-fixed.sidebar-main .sidebar-content{
        width:56px
    }
    .sidebar-fixed.sidebar-default .sidebar-content{
        width:259px
    }
    .sidebar-fixed-expanded .sidebar-fixed.sidebar-main{
        position:fixed;
        display:block;
        height:100%;
        z-index:1001
    }
    .sidebar-fixed-expanded .sidebar-fixed.sidebar-main .sidebar-content{
        position:fixed
    }
    .sidebar-xs .sidebar-fixed-expanded .sidebar-fixed.sidebar-main{
        width:260px
    }
    .sidebar-fixed-expanded .content-wrapper{
        padding-left:56px
    }
}

.navbar {
    padding: 0px;
    margin-right: 15px;

}

.bg-light {
    background-color: #FFF !important;
}

.text-primary {
    color: #333 !important;
}

.page-title {
    padding: 1px 36px 32px 0 !important;
}

#member-active {
    margin-top: 2vh !important;
}

#member-active .caption {
    padding: 10px !important;
}

#member-active .btn-group {
    height: 36px !important;
}

.nav li{
    margin-right: 15px !important;
}

.navbar-nav a{
    color: #333 !important;
}