.MuiDialog-paperWidthSm{
    width: 100% !important;
}

.MuiDialogActions-root{
    display: block !important;
    justify-content: unset !important;
    padding: 25px !important;
}

.btn-danger{
    background-color: #BF0000 !important;
    border-color: #BF0000 !important;
}

.btn-danger:hover{
    background-color:#d2322d !important;
    border-color: #ac2925 !important;
}
#contribution > div.MuiDialog-container.MuiDialog-scrollPaper > div{
    width: 350px !important;
}

.arrow_box {
	position: relative;
	background: white;
    border: 1px solid #000000;
    padding: 15px;
    width: 50%;
}
.arrow_box:after, .arrow_box:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:after {
	border-color: rgba(213, 213, 213, 0);
	border-bottom-color: white;
	border-width: 15px;
	margin-left: -15px;
}
.arrow_box:before {
	border-color: rgba(0, 0, 0, 0);
	border-bottom-color: #000000;
	border-width: 16px;
	margin-left: -16px;
}
#info > div.MuiDialog-container.MuiDialog-scrollPaper > div{
    box-shadow: none !important;
    background:transparent !important;
}

#settings-tab {
	background-color: #fff;
	color: #000000 !important;
}

.MuiPaper-elevation4 {
    box-shadow: none !important;
}

#simple-tab-0 > span.MuiTab-wrapper, #simple-tab-1 > span.MuiTab-wrapper, #simple-tab-2 > span.MuiTab-wrapper {
	font-size: 12px;
    text-transform: capitalize;
}


/* .choice{
	position: absolute;
    top: 1px;
    left: 0;
}

.choice span{
	border: 2px solid #bf0000 !important;
    display: -moz-inline-box;
    display: inline-block;
    border-radius: 100%;
    text-align: center;
    position: relative;
} */
.radio label, .checkbox label{
	min-height: 1px !important;
}
/* .radioEdit{
	padding: 5px;
} */
/* .textholder{
	margin-left: 5px;
	font-size: 15px;
} */

#sales-modal > div.MuiDialog-container.MuiDialog-scrollPaper > div{
	height: 70vh !important;
}

.basic-navbar-nav > div > div > div > * {
	padding: 10px;
}

#navbar-main > div > div.navbar-header > a > img {
	margin-top: -10px !important;
    height: 4vh !important;
}

.basic-navbar-nav > div > i {
	margin-top: 9px;
}
#vertical-tab-0 > span.MuiTab-wrapper,#vertical-tab-1 > span.MuiTab-wrapper,#vertical-tab-2 > span.MuiTab-wrapper, #vertical-tab-3 > span.MuiTab-wrapper, #vertical-tab-4 > span.MuiTab-wrapper, #vertical-tab-5 > span.MuiTab-wrapper{
	display: block !important;
	font-size: 14px;
}
#vertical-tab-0, #vertical-tab-1, #vertical-tab-2, #vertical-tab-3, #vertical-tab-4, #vertical-tab-5{
	text-align: left
}
.MuiTab-labelIcon .MuiTab-wrapper > *:first-child{
	margin-right: 10px;
}

#panel1a-content > div > p > div {
	width: 100% !important;
}
/* #pie_height_settings{
	overflow-y: hidden !important;
} */

#pieSettings > div.MuiDialog-container.MuiDialog-scrollPaper > div {
	max-height: 100% !important;
}

.chip_input {
	height: 60px !important;
	overflow-y: scroll;
}

.styles_chip-input__3aumB .styles_chip__i-PT7 svg {
	float: right;
}
#pie_height_settings > div > div:nth-child(2) > div:nth-child(1) > div > hr:nth-child(3){
	border-top: none !important;
}
#pie_height_settings > div > div:nth-child(2) > div:nth-child(1) > div > div:nth-child(1) > div{
	margin-bottom: 1.5vh;
}
#pie_height_settings > div > div:nth-child(2){
	overflow: visible !important;
}

.login-container .page-container .login-form {
    width: 385px !important;
}

.form-control-feedback{
	font-size: 16px;
	padding: 10px;
}

#forgot-pass-form{
	margin-top: 2vh;
}

#signup .page-container{
	padding-top: 0;
}

.check_div {
	font-size: 14px;
}

#check_all{
	margin-bottom: 1.5vh;
}

.label_font{
	font-size: 14px;
}

#upload_imgDiv{
	font-size: 14px;
}

.td_nowrap{
	white-space:nowrap;
}

  
  .custom_table_css td, .custom_table_css th {
	padding: 3px;
	border: 1px solid #ddd;
  }
  .custom_table_css th {
	  padding:3px;
	background-color: #f2f2f2;;
	color: black;
  }

  #select_team {
	border: none;
    background-color: transparent;
    font-weight: 500;
    width: 185px;
  }
  .table_custom_summary{
	  width: 100%;
  }
  .table_custom_summary td, .table_custom_summary th {
	border: 1px solid #dddddd;
	text-align: center;
	padding: 2px;
	height: 50;

  }
  
  .table_custom_summary tr:nth-child(even) {
	background-color: #dddddd;
  }


  .table_custom_contribution{
	width: 100%;
}
.table_custom_contribution td, .table_custom_contribution th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 2px;
  height: 50;

}

.table_custom_contribution tr:nth-child(even) {
  background-color: #dddddd;
}


#panel-body-text {
	background: #FFF;
	border-top: 1px solid #DDD;
	border-right: 1px solid #DDD;
	border-left: 1px solid #DDD;
}

#vertical_tabs{
	width: 100%;
}

#account_tabpanel {
	margin-top: 2.5vh;
}

#my-pies{
	width: 135%;
}

#notifSave {
	margin-top: 2vh;
}

.check_div {
	padding: 3px;
}

.rwt__tablist  > button{
	text-align: left;
	font-weight: 600;
}

.rwt__tablist  > button > span{
	margin-left: 10px;
	font-weight: 600;
}

#pie-settings-tab , #integrations-tab , #pie-profile-tab {
	text-transform: uppercase;
	text-align: center;
	font-weight: 100;
}

#pie-settings-tab::after , #integrations-tab::after, #pie-profile-tab::after {
	border-bottom: 2px solid #F06292 !important;
}


#pie-logo {
	width: 58px;
	height: 58px;
}

#metric-unit-row {
	display: none;
}

#overtime-fields-row {
	display: none;
}

#settings-tab , #lumpsum-payment-tab, #remove-terminate-tab {
	text-transform: uppercase;
	text-align: center;
	font-weight: 100;
}

#settings-tab-form, #lumpsum-payment-tab-form {
	margin-top: 2vh;
}

#settings-tab::after, #lumpsum-payment-tab::after, #remove-terminate-tab::after {
	border-bottom: 2px solid #F06292 !important;
}

.remove-term-radio {
	margin-top: 10px !important;
	margin-left: 10px !important;
	font-size: 13px;
}

[data-reach-slider-orientation="horizontal"]{
	width: 230px !important;
}

.slider-val {
	margin-top: 1vh;
	font-weight: bold;
}

.switch-class {
	margin-top: 10px;
}

#home_expansion_panel > p > div > div:nth-child(2) > div:nth-child(1){
	position: relative !important;
}

#member-active > .col-sm-4 {
	width: 31.333333% !important;
	margin-right: 12px;
}

#reset-pie-btns{
	margin-bottom: 7vh;
}

#grunt_img {
	background: no-repeat;
	background-size: 102px;
	background-position: center;
	width: 99px;
	height: 100px;
	border-radius: 50%;
}